import React from 'react';

const User = () => (
  <svg
    width={20}
    height={20}
    fill="none"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M4.167 17.333v.5h1v-.5h-1Zm10.666 0v.5h1v-.5h-1Zm-9.666 0v-.666h-1v.666h1Zm3.5-4.166h2.666v-1H8.667v1Zm6.166 3.5v.666h1v-.666h-1Zm-3.5-3.5a3.5 3.5 0 0 1 3.5 3.5h1a4.5 4.5 0 0 0-4.5-4.5v1Zm-6.166 3.5a3.5 3.5 0 0 1 3.5-3.5v-1a4.5 4.5 0 0 0-4.5 4.5h1ZM10 4.166a3.167 3.167 0 0 0-3.167 3.166h1c0-1.196.97-2.166 2.167-2.166v-1Zm3.167 3.166A3.167 3.167 0 0 0 10 4.167v1c1.197 0 2.167.97 2.167 2.166h1ZM10 10.5a3.167 3.167 0 0 0 3.167-3.167h-1c0 1.197-.97 2.167-2.167 2.167v1Zm0-1a2.167 2.167 0 0 1-2.167-2.167h-1A3.167 3.167 0 0 0 10 10.5v-1Zm0 9.333A8.833 8.833 0 0 1 1.167 10h-1c0 5.43 4.402 9.833 9.833 9.833v-1ZM18.833 10A8.833 8.833 0 0 1 10 18.833v1c5.43 0 9.833-4.402 9.833-9.833h-1ZM10 1.167A8.833 8.833 0 0 1 18.833 10h1c0-5.43-4.402-9.833-9.833-9.833v1Zm0-1C4.57.167.167 4.569.167 10h1A8.833 8.833 0 0 1 10 1.167v-1Z"
      fill="currentColor"
    />
  </svg>
);

export default User;
