import React from 'react';

const TwitterInverse = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path
      d="m30.887 3.2.998-.066a1 1 0 0 0-1.742-.603l.744.669Zm-1.13 6.312a1 1 0 1 0-2 0h2Zm-13.78.176h-1 1Zm0 2.045h1-1ZM1.067 26.667v-1a1 1 0 0 0-.556 1.831l.555-.831ZM3.196 3.2l.936-.35a1 1 0 0 0-1.831-.097l.894.447Zm8.52 19.2.707.707a1 1 0 0 0-.39-1.656l-.317.949Zm15.41-17.123-.741.67.36.399.531-.08-.15-.99Zm2.762-2.01c.11 1.662.01 2.68-.264 3.45-.266.75-.73 1.366-1.56 2.16l1.384 1.445c.93-.892 1.645-1.764 2.06-2.936.409-1.15.493-2.489.376-4.252l-1.996.132Zm-.132 7.8V9.512h-2v1.555h2Zm-14.78-1.38v2.046h2V9.688h-2Zm0 2.046V12.8h2v-1.067h-2ZM22.454 2.2c-4.13 0-7.477 3.354-7.477 7.488h2A5.483 5.483 0 0 1 22.454 4.2v-2Zm5.303 8.867c0 9.243-7.481 16.733-16.706 16.733v2c10.332 0 18.706-8.389 18.706-18.733h-2ZM2.26 3.55c1.153 3.08 5.488 9.182 13.717 9.182v-2C8.87 10.733 5.11 5.458 4.133 2.85l-1.873.702Zm.042-.798c-2.227 4.46-2.566 8.777-.953 12.45 1.61 3.663 5.075 6.484 10.05 8.146l.634-1.898c-4.61-1.538-7.534-4.05-8.853-7.053-1.316-2.995-1.122-6.678.911-10.751l-1.79-.894Zm8.707 18.94c-1.293 1.295-5.17 3.974-9.943 3.974v2c5.453 0 9.811-3.011 11.358-4.56l-1.415-1.414Zm16.86-17.087A7.282 7.282 0 0 0 22.454 2.2v2c1.559 0 2.96.672 3.932 1.747l1.483-1.341Zm-.592 1.66c1.607-.244 3.176-1.088 4.353-2.397L30.144 2.53c-.88.978-2.035 1.585-3.166 1.757l.3 1.977ZM.511 27.497c3.481 2.325 7.131 2.302 10.54 2.302v-2c-3.475 0-6.52-.023-9.43-1.965l-1.11 1.663Z"
      fill="currentColor"
    />
  </svg>
);

export default TwitterInverse;
