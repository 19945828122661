import React from 'react';

const Phone = () => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <path
      d="M13 14.5h-.085C3.09 13.935 1.695 5.645 1.5 3.115A1.5 1.5 0 0 1 2.88 1.5h2.755a1 1 0 0 1 .93.63L7.325 4a1 1 0 0 1-.22 1.08L6.04 6.155a4.685 4.685 0 0 0 3.79 3.8l1.085-1.075A1 1 0 0 1 12 8.675l1.885.755a1 1 0 0 1 .615.93V13a1.5 1.5 0 0 1-1.5 1.5ZM3 2.5a.5.5 0 0 0-.5.5v.04C2.73 6 4.205 13 12.97 13.5a.5.5 0 0 0 .53-.47v-2.67l-1.885-.755-1.435 1.425-.24-.03C5.59 10.455 5 6.105 5 6.06l-.03-.24 1.42-1.435L5.64 2.5H3Z"
      fill="currentColor"
    />
  </svg>
);

export default Phone;
