import React from 'react';

const Cycle = () => (
  <svg
    width={51}
    height={54}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={'0 0 51 54'}
  >
    <path
      d="M2.757 27.837h-.033a.753.753 0 0 1-.714-.788c.315-7.305 3.903-14.019 9.842-18.42C17.704 4.292 25.106 2.9 32.164 4.813a.753.753 0 0 1 .528.924.745.745 0 0 1-.915.534c-6.62-1.791-13.56-.488-19.043 3.576-5.573 4.129-8.939 10.424-9.233 17.271a.745.745 0 0 1-.744.719ZM26.021 52.4a24.09 24.09 0 0 1-8.833-1.682C10.055 47.896 4.82 41.97 2.829 34.46a.754.754 0 0 1 .528-.925.746.746 0 0 1 .915.534c1.869 7.044 6.772 12.6 13.458 15.246 6.662 2.634 14.188 1.946 20.13-1.843a.743.743 0 0 1 1.029.236.757.757 0 0 1-.234 1.04C34.863 51.167 30.463 52.4 26.02 52.4ZM42.376 45.53a.736.736 0 0 1-.528-.222.761.761 0 0 1 0-1.067c4.886-4.938 7.235-11.721 6.44-18.61-.807-6.996-4.604-13.089-10.423-16.72a.758.758 0 0 1-.243-1.037.74.74 0 0 1 1.026-.245c6.21 3.877 10.261 10.375 11.122 17.829.846 7.35-1.656 14.585-6.866 19.853a.75.75 0 0 1-.528.218Z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.4}
    />
    <path
      d="M28.096 8.498a.744.744 0 0 1-.65-.385.76.76 0 0 1 .285-1.027l3.191-1.813-1.833-2.915a.759.759 0 0 1 .231-1.04.743.743 0 0 1 1.032.233l2.256 3.589a.759.759 0 0 1-.264 1.06l-3.879 2.204a.785.785 0 0 1-.369.094ZM42.377 45.528a.74.74 0 0 1-.528-.221.77.77 0 0 1-.22-.555l.12-4.495c.013-.415.35-.724.766-.734.411.012.738.358.726.776l-.1 3.698 3.417-.094h.021c.402 0 .735.325.747.734a.752.752 0 0 1-.726.776l-4.205.115h-.018ZM1.545 38.753a.754.754 0 0 1-.653-1.112l2.003-3.737a.744.744 0 0 1 1.05-.285l3.791 2.352c.351.218.461.682.245 1.037a.74.74 0 0 1-1.025.248l-3.12-1.934-1.628 3.037a.76.76 0 0 1-.663.394Z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.4}
    />
  </svg>
);

export default Cycle;
